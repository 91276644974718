.tooltipContainer {
  position: relative;
  background-color: var(--ambient-base);
  padding: 0.7rem 1rem;
  color: white;
}

.gridContainer {
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 0.75rem;
  font-size: 0.875rem;
}

.tooltipNumber {
  text-align: right;
}

.tooltipDate {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}

.tooltipSentinelType {
  text-align: left;
}
