.container {
  padding: 1rem;
}

.sectionHeader:first-child {
  margin-top: 0;
}

.sectionHeader {
  margin: 1rem 0;
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--color-text);
}

.sectionContent {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--color-text);
}
