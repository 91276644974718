.groupHeader {
  height: 1.8rem;
  vertical-align: bottom;
}

.groupHeader > div[class*='groupHeaderText'] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
}
