.pageContainer {
  padding: 2rem 3rem;
}

.bigNumbersContainer {
  margin: 1.25rem 0;
  display: flex;
}

@media (max-width: 768px) {
  .siteGroupDropdown {
    left: -135px;
  }
}
