.container {
  padding: 2rem 0;
}

.tabBar {
  display: flex;
}

.currentTab {
  margin-right: 2em;
  height: 3.75rem;
  border-bottom: 0.25rem solid var(--accent-basePlus25);
  display: flex;
  align-items: center;
}

.tab {
  margin-right: 2em;
  height: 3.75rem;
  border-bottom: 0.25rem solid var(--ambient-basePlus90);
  display: flex;
  align-items: center;
}

.mobileTitle {
  display: none;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: bold;
  margin: 1rem 1rem 0.5rem 1rem;
}

.topLink {
  color: var(--ambient-baseMinus25);
  font-size: 0.875rem;
  line-height: 2.6rem;
  font-weight: 100;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .tabBarContainer {
    display: none;
  }

  .mobileTitle {
    display: block;
  }

  .container {
    padding: 1rem 0;
  }
}
