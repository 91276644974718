.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.sitesContainer {
  display: flex;
  align-items: center;
}

.sitesCountContainer {
  color: var(--ambient-base);
  font-weight: bold;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1rem;
}

.sitesCountContainer:not(:first-child) {
  margin-left: 0.5rem;
}

.containerSeparator {
  margin-top: 1rem;
  border-right: solid var(--ambient-basePlus90) 0.125rem;
  height: 1.5rem;
  width: 0.1rem;
}

.sitesCountTextColor,
.sitesWithIncidentsCountTextColor {
  font-weight: 700;
  padding-left: 0.9375rem;
}

.sitesCountTextColor {
  color: var(--accent-base);
}

.sitesWithIncidentsCountTextColor {
  color: var(--pink-base);
}

.sitesStatusContainer {
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0.8125rem 0;
}

.status {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.status:not(:last-of-type) {
  padding-right: 1.25rem;
}

.dotPadding {
  padding-right: 0.25rem;
  display: flex;
  align-items: center;
}

.numberText {
  font-weight: bold;
  color: var(--ambient-base);
  padding-left: 0.75rem;
}

.red {
  color: #ea4949;
}

.green {
  color: #70da7f;
}

.orange {
  color: #eb8948;
}

.expandIcon {
  cursor: pointer;
  position: absolute;
  top: 5.5rem;
  right: 2rem;
  z-index: 500;
  color: var(--accent-base);
  background-color: white;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.popupContentContainer {
  padding: 0.625rem;
}

.popupSiteImage {
  height: 4rem;
  width: 4rem;
  margin-right: 0.625rem;
}

.popupTitleSection {
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-weight: 600;
  font-size: 0.75rem;
}

.popupSiteOnlineStatusSection {
  justify-content: flex-end;
}

.popupAddressSection {
  margin-bottom: 0.625rem;
  font-style: italic;
  font-size: 0.625rem;
  color: var(--grey-off-white);
}

.popupIncidentCountSection {
  justify-content: flex-end;
  align-items: center;
}

.noIncidentColor {
  color: var(--accent-base);
}

.activeIncidentColor {
  color: var(--pink-base);
}

.siteInsightsButton {
  width: 100%;
  height: 1.5rem;
}

.tileHeader {
  height: 2.4375rem;
}

@media (max-width: 899px) {
  .sitesContainer {
    justify-content: center;
  }

  .sitesStatusContainer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0.8125rem;
    margin-left: 2rem;
  }
}
