.root {
  padding: 0.5rem;
  background-color: rgba(51, 62, 72);
  position: relative;
}

.tooltipLine {
  padding-top: 0.25rem;
}

.paddingBottom {
  padding-bottom: 0.5rem;
}

.primaryText {
  color: var(--turquoise-base);
  font-size: 1rem;
}

.secondaryTextComment {
  color: var(--medium-base);
  font-size: 0.75rem;
}

.secondaryText {
  color: var(--ambient-basePlus75);
  font-size: 0.625rem;
}

.offlineText {
  color: var(--ambient-basePlus75);
  font-size: 1rem;
}

.italics {
  font-style: italic;
}

.override {
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  padding-top: 0.5rem;
  border-top: var(--medium-base) thin solid;
}

.separatorLine {
  border-top: var(--ambient-basePlus25) thin solid;
}

.localOverride {
  font-size: 0.5rem;
}

.energyboxOverrideText {
  font-size: 0.625rem;
  color: var(--pink-base);
}

.commentWrapper {
  text-align: left;
  color: white;
  width: 15rem;
}

.commentValue {
  color: #2addd0;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

.commentTextContainer {
  border-top: thin solid var(--medium-base);
  font-size: 0.75rem;
}

.commentText {
  max-width: 18rem;
  margin-bottom: 0.5rem;
  font-style: italic;
}

.commentType {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}
