.wrapper {
  position: relative;
}

.button {
  display: flex;
  color: var(--ambient-base);
}

.arrowIcon {
  margin-left: 0.5rem;
  color: var(--accent-base);
}

.openArrowIcon {
  margin-left: 0.5rem;
  color: var(--color-white);
}

.button:disabled > .arrowIcon,
.button:disabled > .openArrowIcon {
  color: var(--ambient-basePlus75);
}

.wrapper:hover > button:enabled {
  border-radius: 0.3125rem;
  color: var(--accent-base);
  background-color: var(--accent-basePlus90);
  border: 1px solid var(--accent-base);
}

.wrapper:hover > button.buttonOpen:enabled {
  color: var(--color-white);
  background-color: var(--accent-baseMinus25);
}

.buttonActive {
  border-radius: 0.3125rem;
  color: var(--accent-base);
  border: 1px solid var(--accent-base);
}

.buttonOpen {
  color: var(--color-white);
  background-color: var(--accent-baseMinus50);
  border: 1px solid var(--accent-base);
}

.dropdownButtonWithSelectedItems {
  padding: 0.2rem;
}

.checkbox {
  padding: 1.25rem;
}

.isInCustomDropdownWrapper {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.dropDownWrapper {
  box-shadow: var(--shadow05);
  list-style: none;
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 30.625rem;
  max-height: calc(100vh - 7.5rem);
  overflow-y: auto;
  background-color: white;
  z-index: 100;
  padding: 0.75rem 0 0.5rem;
}

.listWrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}

.listInCustomDropdown {
  padding: 0 1rem;
}

.tagWrapper {
  margin: 0.625rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.selectAll {
  display: flex;
  color: var(--accent-base);
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.75rem 0;
}

.selectAll:hover {
  cursor: pointer;
}

.selectAllInCustomDropdown {
  padding: 0.75rem 1rem;
  overflow: hidden;
}

.filterMenuContentSingleGroup {
  padding: 0;
}

.filterMenuContentSingleGroup > ul > li {
  padding: 0.5rem 2.5rem;
}
.filterMenuContentSingleGroup > ul > li {
  border-bottom: 1px solid var(--ambient-basePlus90);
}

.footerInCustomDropdown {
  padding: 0.8rem 1rem;
}

.marginBottom {
  margin-bottom: 1rem;
}

.genericFilter {
  margin-right: 1rem;
}

.dropDownWrapperOrgHvac {
  box-shadow: var(--shadow05);
  list-style: none;
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 18.625rem;
  max-height: calc(100vh - 7.5rem);
  overflow-y: auto;
  background-color: white;
  z-index: 100;
  padding: 0.75rem 0 0.5rem;
}
