.modalContent {
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  line-height: 1.5rem;
}

.btnLink {
  padding: 0;
  margin: 0;
  outline: none;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: #00a0ae;
  font-size: 1rem;
  line-height: 18px;
  font-weight: 700;
}

.btnLink:hover {
  color: #00a0ae;
  background: transparent;
  text-decoration: blink;
}

.closeIcon {
  color: #00a0ae;
  font-size: 25px;
  padding: 0;
  margin: 0;
  line-height: 0;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: absolute;
  top: 5px;
  right: 6px;
  background: transparent;
  outline: none;
  cursor: pointer;
  font-weight: 600;
}
.closeIcon span {
  position: relative;
  transform: rotate(45deg);
}
.closeIcon span:before {
  content: '';
  height: 17px;
  width: 2px;
  background: #00a0ae;
  position: absolute;
}
.closeIcon span:after {
  content: '';
  height: 2px;
  width: 17px;
  background: #00a0ae;
  position: absolute;
  left: -7px;
  top: 8px;
}
