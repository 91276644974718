.topTable {
  margin-top: 1.25rem;
  border: none;
}

.topTable th {
  border-top: none;
  color: var(--medium-base);
  padding: 0.3rem 0.5rem;
}

.topTable .index {
  color: var(--medium-base);
}

.topTable td {
  border-bottom: solid var(--shade-10) 1px;
}

.labelRoot {
  width: 9rem;
  text-align: right;
}

.labelHeader {
  font-size: 0.75rem;
  font-weight: 510;
  color: var(--medium-base);
  padding-bottom: 0.5rem;
}

.labelValue {
  color: var(--accent-base);
  font-weight: 600;
  font-size: 1.25rem;
}

@media (max-width: 370px) {
  .labelRoot {
    padding-left: 0.5rem;
    text-align: left;
  }
}
