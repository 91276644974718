.mobileTopBar {
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: 700;
}

.container {
  padding: 2rem 3rem;
  overflow-x: auto;
  min-height: calc(100vh - 7.5rem);
}

.mobileContainer {
  padding: 0.5rem 1rem;
  overflow-x: visible;
}

.mobileFilters {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.5rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.marginBottom {
  margin-bottom: 1rem;
}

.marginLeft {
  margin-left: 1rem;
}

.horizontalSpacerExtraLight {
  height: 1px;
  border-top: solid 1px var(--shade-10);
  margin: 0.5625rem 0;
}

.horizontalSpacerLight {
  height: 1px;
  /* border-top: solid 1px var(--shade-25); */
}

.horizontalSpacerDark {
  height: 1px;
  border-top: solid 1px var(--shade-50);
}

.verticalSpacer {
  width: 1rem;
  margin-left: 2rem;
  border-left: solid 1px var(--shade-25);
}

.mobileHeader {
  color: var(--ambient-basePlus50);
}

.filterLevelOne {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.leftContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

.headerHeading {
  color: #333e48;
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
  font-size: 1.25rem;
  padding-right: 0.5rem;
}

.rightContent {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.searchBoxContainer {
  padding-left: 1.5rem;
  max-height: 2.5rem;
}

.filterLevelThree {
  position: relative;
}

.subHeader {
  display: none;
}

.subHeader {
  padding: -1rem 3rem 0 3rem;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  position: absolute;
}

.graphFilterContainer {
  width: 100%;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
}

.seeAllFlex {
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ambient-basePlus);
  padding-bottom: 20px;
}

.seeAllFlex .seeAllMargin {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.container {
  padding: 2rem 3rem;
  overflow-x: auto;
  min-height: calc(100vh - 7.5rem);
}

.filterBar {
  display: flex;
  margin-top: 1rem;
}

.mobileFilterBar {
  display: none;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: -7px;
}

.seeAllFlexContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.seeAllFlexContent .marginLeft {
  margin-left: 0;
}

.filterContainer > div[class*='TemperatureCompliancePage_marginLeft'] {
  margin-left: 0;
}

.seeAllFlexContent .seeAllMarginContent {
  display: flex;
  align-items: center;
  gap: 20px;
}

.downloadButton {
  min-width: 3.25rem;
  padding: 0.5rem;
}

.root {
  height: 100%;
  width: 100%;
}
