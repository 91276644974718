.container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .container {
    margin-top: 72px;
    height: calc(100% - 72px);
  }
}
