html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

button {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333e48;
  font-weight: 700;
}

h1 {
  font-size: 32px;
  line-height: 34px;
}

h2 {
  font-size: 28px;
  line-height: 30px;
}

h3 {
  font-size: 24px;
  line-height: 26px;
}

h4 {
  font-size: 20px;
  line-height: 22px;
}

h5 {
  font-size: 16px;
  line-height: 18px;
}

h6 {
  font-size: 14px;
  line-height: 16px;
}

p {
  margin-top: 0;
}

table {
  border-collapse: collapse;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0);
}

.leaflet-popup-content {
  margin: 0px;
}

.leaflet-popup-content-wrapper {
  border-radius: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  color: #00a1af;
  font-weight: bold;
}

label sup {
  vertical-align: super;
  font-size: smaller;
  top: 0.8rem;
}

:root {
  --donut-margin: 20%;
}

@media (max-width: 768px), (max-device-width: 768px) {
  /*
   * In mobile safari, input font size must be at least 16px so when user presses an input field,
   * automatic zoom won't be done by the browser
   */
  input,
  textarea {
    font-size: 16px !important;
  }
}
