.container {
  display: flex;
  justify-content: space-between;
}

.cardTitle {
  font-size: 1rem;
  font-weight: bold;
  color: var(--ambient-base);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cardSubtitle {
  font-size: 0.75rem;
  color: var(--ambient-base);
}

.iconsContainer {
  display: flex;
  align-items: center;
}

.mobileTooltip.mobileTooltip.mobileTooltip {
  left: 0;
  transform: none;
}

@media (max-width: 899px) {
  .shortenedCardTitle {
    max-width: 10.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cardToggleMain {
  position: relative;
  width: 100%;
  display: flex;
  margin-left: 30px;
}

.cardTitleEnergyDistribution {
  font-size: 1rem;
  font-weight: bold;
  color: var(--ambient-base);
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
