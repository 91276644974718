.noDataFoundSection {
  text-align: center;
}

.noDataText {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: var(--ambient-basePlus25);
}

.subText {
  font-size: 0.895rem;
  padding: 0.765rem 0;
  color: var(--ambient-basePlus25);
}

@media (max-width: 390px) {
  .noDataText {
    font-size: 1rem;
  }

  .subText {
    font-size: 0.795rem;
  }
}
