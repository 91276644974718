.mobileContainer {
  flex-wrap: wrap;
  justify-content: end !important;
}

.mobileFilterGroup {
  justify-content: end !important;
  width: 100%;
}

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1.25rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.grow {
  flex-grow: 1;
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
