.cardBody {
  display: grid;
  grid-template-columns: [chart] 0.8fr 2rem [siteInfo] 1fr;
}

.chartVisual {
  grid-area: chart;
  grid-row: 1;
  display: flex;
  justify-content: center;
}

.siteInfo {
  grid-area: siteInfo;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topTable {
  border: none;
}

.topTable th {
  border-top: none !important;
  color: var(--medium-base);
  padding: 0.3rem 0.5rem;
}

.topTable td {
  border-bottom: solid var(--shade-10) 1px;
}

@media (max-width: 899px) {
  .cardBody {
    grid-template-columns: [chart] 1fr;
  }

  .siteInfo {
    display: none;
  }
}
