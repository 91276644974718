.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container > :not(:first-child) {
  margin-top: 1.25rem;
}

.containerPadding {
  padding: 1.25rem 3rem;
}

.gapBetween > :not(:first-child) {
  margin-top: 1.25rem;
}

@media (max-width: 768px) {
  .containerPadding {
    padding: 1.25rem 1rem;
  }
}
