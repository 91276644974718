.singleSiteRoot {
  margin: 0 3rem 2rem 3rem;
}

.multiSiteRoot {
  margin: 2rem 3rem;
}

.review {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: solid var(--ambient-basePlus75) 1px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

.filterContainer,
.priorityAndStatusFilters {
  display: flex;
  align-items: center;
}

.filterContainer > *:not(:first-child),
.priorityAndStatusFilters > *:not(:first-child) {
  margin-left: 0.625rem;
}

.mobilePriorityAndStatusFilter {
  display: none;
}

.mobileFilterDropdown {
  width: calc(100vw - 2rem);
  right: -267px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

@media (max-width: 340px) {
  .mobileFilterDropdown {
    right: -188px;
  }
}

@media (max-width: 768px) {
  .filterContainer {
    padding-right: 1rem;
  }

  .singleSiteRoot,
  .multiSiteRoot {
    margin: 2rem 1rem 0 1rem;
  }

  .header {
    justify-content: flex-end;
  }

  .priorityAndStatusFilters,
  .siteFilter,
  .siteGroupFilterContainer {
    display: none;
  }

  .mobilePriorityAndStatusFilter {
    display: block;
  }

  .mobileFilterDropdown {
    left: calc(-29px - 2vw);
    width: 58vw;
    min-width: 360px;
  }
}

@media (max-width: 390px) {
  .mobileFilterDropdown {
    left: -19px;
    width: 90vw;
  }
}
