.tooltipContainer {
  background-color: var(--ambient-base);
  width: 10rem;
}

.tooltipInner {
  position: relative;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
}

.tooltipHeader {
  font-size: 0.625rem;
  font-style: italic;
  color: white;
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: space-between;
}

.tooltipValues {
  font-weight: 600;
  font-size: 0.9375rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  color: var(--accent-base);
}

.tooltipSecondaryInfo {
  margin-top: 0.625rem;
  font-size: 0.625rem;
  color: var(--ambient-basePlus75);
}
