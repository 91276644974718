.table th {
  color: var(--medium-base);
  padding: 0.3rem 0.5rem;
}

.table td {
  border-bottom: solid var(--shade-10) 1px;
}

.index {
  color: var(--medium-base);
}
