.modalContent {
  padding-top: 0.5rem;
}

.congratulationstitle {
  font-size: 1.25rem;
  margin: 2.5rem 2rem 1rem 2rem;
}

.congratulationsContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
}

.congratulationsContent h5 {
  padding-top: 1rem;
  color: #333e48;
  font-size: 1.25rem;
  font-weight: bold;
}
