.pageContainer {
  padding: 2rem 3rem;
}

.verticalPadding {
  padding: 2rem 0;
}

/* Everything below doesn't apply with current implementation,
but keeping b/c we have old tiles archived for now */
.quarterGridContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(18.375rem, 1fr));
  grid-template-rows: minmax(13.375rem, 1fr);
  grid-gap: 1rem;
}

.quarterGridContainer .graphCard {
  grid-column: span 2;
}

.thirdGridContainer {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(20rem, 23.75rem));
  grid-template-rows: 25rem;
  grid-gap: 1rem;
}

.thirdGridContainer .graphCard {
  grid-column: span 2;
}

@media (max-width: 1248px) {
  .quarterGridContainer {
    grid-template-columns: repeat(3, minmax(11.875rem, 1fr));
    grid-template-rows: 13.375rem;
  }
}

@media (max-width: 820px) {
  .quarterGridContainer {
    grid-template-columns: repeat(2, minmax(11.875rem, 1fr));
    grid-template-rows: minmax(13.375rem, 1fr);
    grid-auto-flow: dense;
  }

  .thirdGridContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .quarterGridContainer {
    grid-template-columns: 18.375rem;
    grid-template-rows: 13.375rem;
    grid-auto-flow: dense;
  }

  .quarterGridContainer .graphCard {
    grid-column: span 1;
  }

  .thirdGridContainer {
    grid-template-columns: 18.375rem;
  }
}

.siteFilter {
  margin-left: 0.5rem;
}

.row {
  display: flex;
}

.compactFilterRow {
  display: flex;
}

.siteGroupFilterContainer {
  margin-left: 0.5rem;
}

@media (max-width: 340px) {
  .siteFilterDropdown.siteFilterDropdown {
    right: -66px;
  }

  .siteFilterDropdown div {
    /* 
      Overriding inline searchbox style in React UI:   width={isInDropdown ? '100%' : '29.17rem'} 
      !important flags are bad, this is an exception
    */
    max-width: 250px !important;
  }
}

@media (max-width: 768px) {
  .pageContainer {
    padding: 2rem 1rem 0 1rem;
  }

  .siteFilter {
    margin-left: 0;
  }

  .siteFilterDropdown {
    max-width: 200px;
    right: 25px;
  }

  .row {
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .siteGroupFilterDropdown {
    left: -96px;
  }
}

.mobileFilterDropdown {
  left: calc(12rem - 50vw);
  max-width: 80vw;
  min-width: 65vw;
}

@media (max-width: 390px) {
  .mobileFilterDropdown {
    left: -60vw;
    width: 90vw;
  }
}
