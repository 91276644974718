.root {
  color: var(--medium-base);
}

.isRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem;
}

.leftAlignContent {
  text-align: left;
}

.rightAlignContent {
  text-align: right;
}

.title {
  font-size: 0.825rem;
  color: var(--ambient-baseMinus75);
  padding-bottom: 0.25rem;
}

.rowTitle {
  padding-bottom: 0;
  font-size: 0.875rem;
}

.bigNumber {
  color: var(--ambient-baseMinus75);
  font-size: 1.4em;
}

.bigNumberUnit {
  margin-left: 2px;
  margin-right: 2px;
  font-size: 0.825em;
}

.bigNumberSubTitle {
  font-size: 0.65em;
  color: var(--medium-base);
  font-weight: normal;
}

.bold {
  font-weight: 600;
  color: var(--ambient-baseMinus75);
}

.subtext {
  font-size: 0.625rem;
  font-weight: 600;
  color: var(--ambient-base);
}
