.root {
  width: 20rem;
  min-height: 20rem;
  background-color: white;
  box-shadow: var(--shadow10);
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1rem;
}

.mobileRoot {
  width: auto;
}

.mobileModalContainer {
  min-width: 20rem;
  width: 80%;
}

.content {
  padding: 1.25rem 1.25rem 0.75rem 1.25rem;
}

.container:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.borderTop {
  border-top: thin solid var(--ambient-basePlus75);
  padding-top: 0.5rem;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}

.flexContainer {
  display: flex;
}

.commentTypeLabel {
  font-weight: bold;
  margin-right: 0.75rem;
}

.textBox {
  border: thin solid var(--ambient-basePlus75);
  width: 100%;
  height: 5rem;
  resize: vertical;
}

.textBox::placeholder {
  color: var(--ambient-basePlus75);
  font-style: italic;
  margin-bottom: 0.2rem;
}

.italicGrayText {
  color: var(--ambient-basePlus50);
  font-style: italic;
  margin-bottom: 0.2rem;
}

.textBoxCounter {
  margin-top: 0.15rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  color: var(--ambient-basePlus75);
}

.resourceTitle {
  margin-right: 0.5rem;
}

.resourceValue {
  color: #2addd0;
}

.currentValue {
  color: #626df9;
}

.grayText {
  color: var(--ambient-basePlus50);
}

.actionsContainer {
  width: 100%;
  height: 100%;
  background-color: var(--shade-05);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.actionButtons {
  padding: 0.5rem 1rem 0.5rem 0;
}
