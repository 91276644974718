.container {
  position: relative;
  width: 22rem;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  /* box-shadow: 0 0 10px 0 rgba(160, 160, 160, 0.5);
   */
  /* Give z-index show that shadow renders on top of map */
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}

.containerCollapsed {
  display: none;
}

.toggle {
  width: 5.3125rem;
  position: absolute;
  left: -5.3125rem;
  height: 4.375rem;
  background: #fff;
  top: 48%;
  transform: translate(0, -15px) rotate(-0deg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--accent-base);
  cursor: pointer;
  border-radius: 3px 0px 0px 3px;
  border-right: none;
  box-shadow: 0px 0 5px rgba(204, 204, 204, 0.5);
}

.toggleCollapsed {
  width: 2.3125rem;
  left: -2.3125rem;
  box-shadow: none;
}

.siteList {
  margin-left: 1.375rem;
}

.toggle:hover {
  color: var(--accent-basePlus25);
}

.searchBoxContainer {
  top: 0;
  position: sticky;
  z-index: 1000;
  height: 2.375rem;
  overflow: hidden;
}

.searchFieldContainer {
  top: 2.375rem;
  background-color: #fff;
  position: sticky;
  z-index: 1000;
  padding-top: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.title {
  margin-block-end: 0;
  margin-bottom: 0.625rem;
}

.resultCount {
  font-size: 0.75rem;
  text-align: right;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.searchResults {
  overflow-y: auto;
  flex: 1;
  max-height: 65vh;
}

.resultCountPartial {
  font-size: 0.75rem;
  text-align: right;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--orange-base);
}

.resultCountOffline {
  font-size: 0.75rem;
  text-align: right;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--pink-base);
}

.statusDotPartial {
  margin-left: 0.5rem;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--orange-base);
  transform: translate(0, 10%);
}

.statusDotOffline {
  margin-left: 0.5rem;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--pink-base);
  transform: translate(0, 10%);
}

.hideButton {
  background-color: var(--ambient-basePlus90);
  font-size: 0.75rem;
  line-height: 1.125rem;
  width: 100%;
  border: none;
  box-sizing: border-box;
  padding: 0.6875rem 0.75rem 0.6875rem 1.25rem;
  bottom: 0;
  position: sticky;
  z-index: 999;
}

.hideText,
.hideText svg {
  vertical-align: middle;
}
