.mobileNotificationsNavBar {
  display: none;
  padding: 1rem;
}

.notificationsNavBarInner {
  display: flex;
  flex-direction: row;
}

.currentTab {
  margin-right: 2em;
  height: 3.75rem;
  border-bottom: 0.25rem solid var(--accent-basePlus25);
  display: flex;
  align-items: center;
}
.tab {
  margin-right: 2em;
  height: 3.75rem;
  border-bottom: 0.25rem solid var(--ambient-basePlus90);
  display: flex;
  align-items: center;
}

.topLink {
  color: var(--ambient-baseMinus25);
  font-size: 0.875rem;
  line-height: 2.6rem;
  font-weight: 100;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .notificationsNavBar {
    display: none;
  }

  .mobileNotificationsNavBar {
    display: block;
    width: 100vw;
    position: sticky;
    top: 40px;
    z-index: 1;
    background-color: var(--color-white);
  }
}
