.title {
  margin: 0px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1rem;
  color: var(--ambient-base);
}

.content {
  padding: 1.875rem;
  min-height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.graphContent {
  padding: 1.875rem 1rem 0.75rem 0rem;
  min-height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.graphHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bigNumber {
  font-size: 2rem;
  font-weight: bold;
  color: var(--ambient-base);
  display: flex;
  align-items: flex-end;
}

.bigRedNumber {
  font-size: 2rem;
  font-weight: bold;
  color: var(--pink-base);
}

.bigOrangeNumber {
  font-size: 2rem;
  font-weight: bold;
  color: var(--orange-base);
}

.redAlertIcon {
  color: var(--pink-base);
}

.orangeAlertIcon {
  color: var(--orange-base);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowNoSpace {
  display: flex;
  flex-direction: row;
}

.tooltipContainer {
  background-color: var(--ambient-base);
  width: 10rem;
  text-align: center;
}

.tooltipInner {
  position: relative;
  padding: 0.5rem;
}

.tooltipValues {
  margin-top: 0.75rem;
  color: var(--accent-base);
}

.ebColor {
  color: var(--accent-base);
}

.marginLeft {
  margin-left: 1.5rem;
}

.graphHeaderMarginRight {
  margin-right: 0.3rem;
}

.marginTop {
  margin-top: 1rem;
}

.noNotifications {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9.75rem;
}

.cursorPointer {
  cursor: pointer;
}

.height {
  height: 100%;
}
