.tilesContainer {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 23.75rem);
  grid-auto-rows: 26.875rem;
  grid-auto-flow: dense;
  grid-gap: 1.25rem;
}

.singleTile {
  grid-column-start: span 1;
}

.doubleTile {
  grid-column-start: span 2;
}

.tripleTile {
  grid-column-start: span 3;
}

.tileContainer {
  position: relative;
  height: 100%;
  padding: 1.25rem;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  height: 3.4375rem;
  align-items: flex-start;
}

.contentContainer {
  height: 18.75rem;
}

.actionContainer {
  height: 1.875rem;
  margin-top: 0.3125rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.75rem;
}

@media (max-width: 2100px) {
  .tilesContainer {
    grid-template-columns: repeat(3, 23.75rem);
  }
}

@media (max-width: 1299px) {
  .tilesContainer {
    grid-template-columns: repeat(auto-fill, 23.75rem);
  }

  .singleTile {
    grid-column-start: span 2;
  }

  .tripleTile {
    grid-column-start: span 2;
  }
}

@media (max-width: 899px) {
  .actionContainer {
    padding-top: 0.5rem;
    min-height: 2.875rem;
    max-height: none;
  }

  .singleTile {
    grid-column-start: span 1;
  }

  .doubleTile {
    grid-column-start: span 1;
  }

  .tripleTile {
    grid-column-start: span 1;
  }
}
