.circle {
  stroke-width: 6px;
  fill: transparent;
}

.incidentColor {
  stroke: var(--pink-base);
}

.normalColor {
  stroke: var(--accent-base);
}
