.sensorIconAndTextGrouping {
  display: flex;
  align-items: center;
}

.sensorTypeText {
  max-width: 10rem;
  text-overflow: ellipsis;
}

.secondaryTextBefore {
  margin-right: 0.25rem;
}

.secondaryTextAfter {
  margin-left: 0.25rem;
}

.secondarySensorValueText {
  font-style: italic;
  font-size: 0.75rem;
}

.inlineLabel {
  display: flex;
  align-items: center;
}
