.yUnitLabel {
  margin-left: 4em;
  margin-bottom: 1em;
  font-size: 10px;
  color: var(--base50);
}

.tooltipContainer {
  background-color: var(--ambient-base);
  width: 10rem;
}

.tooltipInner {
  position: relative;
  padding: 0.625rem 0.625rem 0.3rem 0.625rem;
}

.tooltipHeader {
  font-size: 0.75rem;
  font-style: italic;
  color: white;
}

.tooltipValues {
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-between;
  color: var(--accent-base);
}

.space {
  height: 1.25em;
  flex-grow: 1;
}

.axisLabel {
  fill: rgba(51, 62, 72, 0.5);
}
