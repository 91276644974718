.root {
  width: 100%;
  height: 300px;
  padding-right: 2rem;
}

.yUnitLabel {
  width: 2rem;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: var(--base50);
}

@media (max-width: 768px) {
  .root {
    scrollbar-width: thin;
    scrollbar-color: var(--accent-base) var(--ambient-basePlus90);

    overflow-x: auto;
    white-space: nowrap;
  }

  .root::-webkit-scrollbar {
    width: 1px;
  }

  .root::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  .root::-webkit-scrollbar-thumb {
    background-color: var(--accent-base);
    border-radius: 10px;
  }

  /* Firefox */
  .root::-webkit-scrollbar-thumb {
    height: 5px;
  }
}
