.index {
  color: var(--medium-base);
}

.contentContainer {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mobileContentContainer {
  margin-left: 0;
  margin-right: 0;
}

.scrollOnOverflow {
  overflow-y: auto;
}

.mobileScrollOnOverflow {
  overflow-x: hidden;
  overflow-y: auto;
}

.table th {
  color: var(--medium-base);
  padding: 0.3rem 0.5rem;
}

.headerTitle {
  white-space: normal;
}

.mode {
  font-size: 0.75rem;
}
