.title {
  margin-top: 1rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
}

.payload {
  font-size: 0.875rem;
  line-height: 1.5;
}

.actions {
  text-align: right;
}
