.root {
  text-align: left;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.rightAlign {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 1rem;
}

.dateFilter {
  margin-left: 1rem;
}

.thresholdsContainer {
  padding-top: 1rem;
}

.chartContainer {
  position: relative;
}

.commentButtonContainer {
  margin-right: 0.5rem;
}
