.mobileSiteNavBar {
  display: none;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .mobileSiteNavBar {
    display: block;
    width: 100vw;
    padding: 1rem;
    position: sticky;
    top: 40px;
    z-index: 1;
    background-color: var(--color-white);
  }
}
