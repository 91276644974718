.row {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.row:first-of-type {
  margin-top: 0;
}

.value {
  padding-right: 1rem;
}

.horizontalDivider {
  height: 1px;
  width: 100%;
  background-color: var(--ambient-basePlus90);
}
