.container {
  --headerHeight: 2.2rem;
  display: flex;
  flex-direction: column;
}

.topBar {
  border-top: 1px solid var(--ambient-basePlus75);
}

.headerWrapper {
  position: relative;
}

.header {
  display: flex;
  position: absolute;
  padding: 0 0.5rem;
  font-size: 0.875rem;
  color: #000;
  font-weight: 600;
  height: var(--headerHeight);
  align-items: center;
}

/* div[class*='Table_container'] {
  border-top: 1px solid var(--ambient-basePlus75);
} */

div[class*='TableHeader_rowLimitControl'] {
  height: var(--headerHeight);
  margin-bottom: 0;
}
