.grouping {
  display: flex;
  align-items: center;
}

.icon {
  padding-right: 1.3125rem;
}

.text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.secondaryText {
  font-size: 0.75rem;
  padding-top: 0.2rem;
  opacity: 0.4;
}
