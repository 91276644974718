.button {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.text {
  height: 0.875rem;
  font-weight: 600;
}

.arrowIcon {
  font-size: 1rem;
  width: 1.875rem;
}
