.flex {
  display: flex;
  flex-direction: column;
}

.online {
  color: var(--dark-base);
}

.offline {
  color: var(--pink-base);
}

.buffer {
  color: var(--medium-base);
}
