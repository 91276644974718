.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.messageContainer {
  margin-bottom: 2rem;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--ambient-base);
  margin-bottom: 0.5rem;
}

.tilesMenuDropdown {
  position: absolute;
  top: 20px;
  right: 20px;
}
