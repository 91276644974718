.container {
  height: 100%;
  flex: 1;
  position: relative;
}

.showAllButton {
  position: absolute;
  top: 1.5rem;
  left: calc(50% - 6rem);
  width: 12rem;
  justify-content: center;
  /* Make button appear on top of map */
  z-index: 1000;
}

.legends {
  position: absolute;
  top: 0.75rem;
  left: 3.5rem;
  width: 14.25rem;
  z-index: 1000;
  height: 4rem;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 0.75rem;
  padding: 1rem;
  color: var(--ambient-base);
}

.legends span {
  display: inline-block;
  vertical-align: middle;
}

.legends svg {
  vertical-align: middle;
}

.legends div:first-child {
  margin-bottom: 0.2rem;
}

.legendRow {
  display: flex;
  align-items: center;
}

.redMarker {
  display: inline-block;
  margin-right: 0.5rem;
  color: var(--pink-base);
}

.blueMarker {
  display: inline-block;
  margin-right: 0.5rem;
  color: var(--accent-base);
}

@media (max-width: 768px), (max-device-width: 768px) {
  .showAllButton {
    display: none;
  }

  .legends {
    bottom: 3rem;
    left: calc(50% - 14.25rem / 2);
    top: auto;
  }
}
