.root {
  --headerHeight: 2.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: var(--headerHeight);
}

input {
  margin: 0;
}

.toolTipContainer {
  margin-left: 10px;
  position: relative;
  /* top: 3px; */
}

.toolTipIcon {
  vertical-align: bottom;
}

.leftContainer {
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
  align-self: center;
}

.rightContainer {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.rightContainer > div[class*='SearchWrapper_root'] {
  max-height: var(--headerHeight);
}

.headerHeading {
  /* color: #333e48; */
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
  font-size: 1.25rem;
  padding-right: 0.5rem;
}

@media (max-width: 768px), (max-device-width: 768px) {
  /* start - make search bar expand */
  .root > div[class*='leftContainer'] {
    display: none;
  }

  .root > div[class*='rightContainer'] {
    width: 100%;
  }
  /* end - make search bar expand */

  /* .rightContainer > div[class*='SizedRadioGroup_container'] {
    display: none;
  } */
}
