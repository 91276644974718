.row {
  display: flex;
  align-items: center;
}

.incidentCount {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--pink-base);
}
