.root {
  text-align: left;
}

.timezone {
  color: white;
  font-style: italic;
  font-size: 0.625rem;
  margin-top: 0.35rem;
  text-align: left;
}
