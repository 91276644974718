.root {
  height: 100%;
  width: 100%;
}

.license_wrapper {
  padding: 1rem;
  box-shadow: var(--shadow10);
  overflow-x: scroll;
  min-height: 30rem;
}

.public_license_wrapper {
  padding: 1rem;
  overflow-x: scroll;
  min-height: 30rem;
}

.loader {
  position: relative;
  margin: 0 auto;
  margin-top: 12rem;
}

.license_div {
  margin-top: 1.5rem;
}

@media screen and (max-width: 600px) {
  .root {
    padding: 0;
  }

  .license_wrapper {
    padding: 1rem;
    box-shadow: none;
    overflow-x: visible;
    min-height: 30rem;
  }

  .loader {
    position: relative;
    margin: 0 auto;
    margin-top: 12rem;
  }
}
