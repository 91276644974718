.resultContainer {
  cursor: pointer;
}

.resultContainer:hover {
  background-color: var(--turquoise-basePlus90);
}

.resultContainer.open {
  background-color: var(--turquoise-basePlus75);
}

.innerContainer {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.25fr;
  align-items: center;
  border-top: solid 1px var(--ambient-basePlus90);
  padding: 0.625rem 1.25rem;
}

.siteImage {
  position: relative;
  width: 5.625rem;
  height: 4.0625rem;
}

.siteImage svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.descContainer {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  flex: 1;
  align-self: stretch;
}

.siteTitle {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 0.625rem;
  margin-right: 0.625rem;
}

.siteAddress {
  font-size: 0.75rem;
  font-style: italic;
}

.icons {
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

.count {
  color: var(--ambient-base);
}

.iconRed {
  color: var(--pink-base);
}
