.root {
  display: flex;
  width: 100vw;
}

.routeContainer {
  width: calc(100% - var(--application-menu-width));
  margin-left: var(--application-menu-width);
}

.content {
  overflow-y: auto;
  flex-grow: 1;
  height: calc(100vh);
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .content {
    overflow-x: hidden;
    height: auto;
  }

  .routeContainer {
    width: calc(125% - var(--application-menu-width));
    margin: 0;
  }
}
