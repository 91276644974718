.root {
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
  touch-action: pan-x pan-y; /* disable default zoom-in/out behavior */
}

.loading {
  position: relative;
  top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 0;
  z-index: 1;
}

.legendContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.legendItem {
  display: flex;
  align-items: center;
  height: 1.5rem;
  margin-right: 1rem;
}

.lineIcon {
  height: 1rem;
  margin-right: 0.5rem;
}

.boxIcon {
  padding-right: 0.5rem;
}

.iconCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.yAxisLabel {
  width: 1rem;
  margin-bottom: 1rem;
  font-size: 0.625rem;
  color: var(--ambient-basePlus50);
  text-align: right;
}

/* this is needed to make sure yAxis ticks 
display correctly if they're too long */
.root div svg:global(.recharts-surface) {
  overflow: visible;
}

.legendText {
  margin-left: 8px;
}
