.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-weight: bold;
}

.mobileHeaderSearchBox,
.mobileTable {
  display: none;
}

.mobileSiteTitle {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.mobileSiteNotificationCount {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .navBar,
  .table,
  .headerTitle,
  .headerSearchBox {
    display: none;
  }

  .mobileHeaderSearchBox,
  .mobileTable {
    display: block;
  }

  .container {
    padding-top: 0;
  }

  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }

  .mobileHeaderSearchBox {
    width: 100%;
  }
}

.currentTab {
  display: flex;
}

.currentTab .topLink {
  margin-left: 10px;
  color: #000000;
}

.icontitleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000000;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Open Sans';
  font-style: normal;
}

.icontitleContainer svg {
  margin-right: 15px;
}

.DownloadedStats {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.DownloadIconContainer {
  /* No content, just for easy reference for UI test */
}

.DownloadedStats .menuDropdownContainer {
  margin-left: 15px;
}

.dateCreated {
  text-align: right;
}

.dateCreated .formattedTime {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333e48;
}

.fileSize {
  text-align: right;
}

.statusWrap .statusGenerating {
  color: #fd8c41;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.statusWrap .statusGenerating span {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #fd8c41;
  border-radius: 50%;
  width: 17px;
  height: 18px;
  animation: spin 2s linear infinite;
  display: inline-block;
  margin-left: 5px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.statusWrap .statusSuccess {
  color: green;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.statusWrap .statusError {
  color: red;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
