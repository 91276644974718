.root {
  display: flex;
  height: 1.5rem;
}
/* .root > div {
  min-width: 0;
} */

.dismissedContent {
  display: flex;
  flex-direction: column;
}

.topText {
  padding-bottom: 1.75rem;
  text-align: left;
}

.iconAndTextGrouping {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textGrouping {
  text-align: left;
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  padding-left: 15px;
  min-width: 0;
}
.circle {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  background: var(--purple-baseMinus25);
}

.dismissedAt {
  font-style: italic;
}
