/* this is needed to make sure yAxis ticks 
display correctly if they're too long */
.root div svg:global(.recharts-surface) {
  overflow: visible;
}

.loading {
  position: relative;
  top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 0;
  z-index: 1;
}
