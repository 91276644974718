.mobileTopNavBar {
  display: none;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .content {
    overflow: hidden;
  }

  .topNavBar {
    display: none;
  }

  .mobileTopNavBar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }
}
