.tableHeader {
  word-wrap: break-word;
}

.tableHeader > div > div:hover {
  overflow: visible;
}

.tableGroupHeader {
  padding: 0 0.5rem !important;
}

.tooltipText {
  white-space: break-spaces;
}

.red {
  color: var(--notification-red);
}

.green {
  color: var(--alert-green);
}

.incomplete {
  color: var(--ambient-basePlus75);
}
