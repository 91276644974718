.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.progress {
  color: var(--ambient-base);
}
