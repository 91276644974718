.root {
  display: flex;
}

.container {
  flex-direction: row;
  gap: 1.25rem;
}

.mobileContainer {
  --spacePadding: 0.5625rem;
  flex-direction: column;
  border-top: 1px solid var(--shade-10);
  padding: var(--spacePadding) 0;
  gap: var(--spacePadding);
}

.mobileContainer + div[class*='TableWrapper_container'] {
  margin-top: 0;
}

.verticalDivider {
  background-color: var(--shade-25);
}

.horizontalDivider {
  background-color: var(--shade-10);
}
