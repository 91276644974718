.root {
  display: grid;
  grid-template-columns: 8.5rem 12.5rem 12.5rem 8.5rem;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin: 2.5rem;
  padding: 2rem;
  font-size: 1em;
}

.root:not(:last-child) {
  margin-bottom: 3rem;
}
