.link {
  /* position: absolute; */
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: #00a1af;
  text-align: right;
  bottom: 0;
  margin-top: 1rem;
}

.link a:hover,
.link a:visited {
  text-decoration: none;
  color: #00a1af;
}

.arrow {
  position: relative;
  top: 0.1rem;
}

.tileTitle {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
