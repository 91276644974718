.siteTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 0.875rem;
}

.index {
  color: var(--medium-base);
}
