.root {
  width: 100%;
  height: 100%;
}

.yUnitLabel {
  width: 2rem;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 0.65rem;
  color: var(--base50);
}
