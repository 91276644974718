.buttonContainer {
  min-width: 2rem;
  width: 2.5rem;
  height: 2rem;
  padding: 0;
}

.buttonContainer[disabled] {
  color: white !important;
}

.tooltip {
  top: 120% !important;
}
