.navWrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.settingsNav {
  background: #000000;
  opacity: 0.75;
}

.calendarIcon {
  margin-bottom: -6px;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .settingsNav {
    display: none;
  }
}
