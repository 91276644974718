.contentContainer {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mobileContentContainer {
  margin-left: 0;
  margin-right: 0;
}

.scrollOnOverflow {
  overflow-y: auto;
}

.mobileScrollOnOverflow {
  overflow-x: hidden;
  overflow-y: auto;
}

.table th {
  color: var(--medium-base);
  padding: 0.3rem 0.5rem;
}

.sentinelTitle {
  margin-bottom: 0.25rem;
}

.incidentPriorityLine {
  width: 0.2rem;
  height: 3.25rem;
}

.icon {
  margin-top: 0.25rem;
}

.incidentPriority-dismissed {
  background-color: var(--ambient-basePlus50);
}
.icon-dismissed {
  color: var(--ambient-basePlus50);
}

.incidentPriority-normal {
  background-color: var(--notification-blue);
}
.icon-normal {
  color: var(--notification-blue);
}

.incidentPriority-medium {
  background-color: var(--notification-yellow);
}
.icon-medium {
  color: var(--notification-yellow);
}

.incidentPriority-high {
  background-color: var(--notification-orange);
}
.icon-high {
  color: var(--notification-orange);
}

.incidentPriority-critical {
  background-color: var(--notification-red);
}
.icon-critical {
  color: var(--notification-red);
}

.dismissCheckboxContainer {
  height: 100%;
  border-left: solid 1px var(--shade-10);
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileDismissCheckboxContainer {
  padding: 0.4rem;
}

.radioButtonContainer {
  margin-right: 1.25rem;
}
