.root {
  position: relative;
}

.capHeight {
  max-height: 100%;
}

.margin {
  padding: 1.25rem 3rem;
}

.fillFrame {
  height: 100%;
}

.hasFilterBar {
  padding: 2.5rem 3.5rem;
}

.gapBetween > :not(:first-child) {
  margin-top: 1.25rem;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .margin {
    padding: 1rem;
  }
}
