.container {
  text-align: left;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.energyLineChartContainer {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.rightAlign {
  display: flex;
  align-items: center;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.commentButtonContainer {
  margin-right: 0.5rem;
}
