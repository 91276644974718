.iconAndTextGrouping {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;
}

.iconAndTextGrouping .defaultIconStyle {
  display: flex;
  align-items: center;
  padding-right: 0.375rem;
}
