.root {
  margin-left: 0.5rem;
}

.button {
  width: 6.5rem;
}

.tooltipContent {
  width: 20rem;
  text-align: left;
  white-space: normal;
  font-size: 0.75rem;
}

.active {
  background-color: var(--accent-baseMinus50);
}

.bold {
  font-weight: bold;
  margin-bottom: 1rem;
}
