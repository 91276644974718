.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  display: flex;
  background-position: center;
  object-fit: contain;
}

.imageSizeTiny {
  width: 4rem;
  height: 3rem;
}

.imageSizeSmall {
  width: 5.625rem;
  height: 4.0625rem;
}

.imageSizeMedium {
  width: 9rem;
  height: 6.625rem;
}

.placeholderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
