.actions {
  margin: 2rem;
  text-align: center;
}

.bigNumbersContainer {
  margin-bottom: 1.25rem;
  display: flex;
}

.mobileHeader {
  color: var(--shade-75);
}

.activeText {
  color: var(--pink-base);
}

.resolvedText {
  color: var(--medium-base);
}

.dismissCheckboxContainer {
  height: 100%;
  border-left: solid 1px var(--shade-10);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartPadding {
  padding: 1.875rem;
}

.groupHeader {
}

.iconAndTitle {
  display: flex;
  flex-direction: row;
}

.equipmentTitle {
  font-size: 0.75rem;
  font-weight: 600;
  padding-bottom: 0.25rem;
}

.sentinelTitle {
  font-size: 0.75rem;
  font-weight: 600;
  padding-bottom: 0.25rem;
}

.sentinelDescription {
  font-size: 0.625rem;
  margin-bottom: 0.625rem;
}

.currentValue {
  margin-top: 0.75rem;
  font-weight: 600;
  font-size: 0.625rem;
}

.duration {
  padding-top: 0.8rem;
}

.icon--normal {
  color: var(--notification-blue);
}

.icon--medium {
  color: var(--notification-yellow);
}

.icon--high {
  color: var(--notification-orange);
}

.icon--highest,
.icon--critical {
  color: var(--notification-red);
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

.priorityBadgesContainer {
  display: flex;
}

.priorityBadges {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  margin-top: 0.5rem;
}

.badge {
  border-radius: 0.4rem;
  padding: 0.25rem 0.5rem;
  color: var(--color-white);
  font-weight: 600;
  font-size: 0.7rem;
}

.badge--normal {
  background-color: var(--notification-blue);
}

.badge--medium {
  background-color: var(--notification-yellow);
}

.badge--high {
  background-color: var(--notification-orange);
}

.badge--highest,
.badge--critical {
  background-color: var(--notification-red);
}

.hr {
  border-bottom: 1px solid var(--shade-25);
  margin: 0.7rem 0;
}

.mobileEquipmentTitleClassName {
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--ambient-base);
}

.mobileResolvedTextContainer {
  display: flex;
  align-items: flex-start;
}

.extraContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .bigNumbersContainer {
    display: none;
  }

  .equipmentTitle {
    margin-left: 0.4rem;
    font-size: 0.6rem;
    line-height: 1.2rem;
    font-weight: 300;
    color: var(--medium-base);
  }

  .sentinelTitle {
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 700;
  }

  .duration {
    padding-top: 0;
  }

  .sentinelDescription {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--ambient-base);
  }

  .startTime,
  .endTime {
    margin-bottom: 0.25rem;
  }

  .timeValues {
    text-align: left;
    color: var(--ambient-base);
  }

  .currentValue {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }

  .currentValueTitle {
    color: var(--ambient-base);
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 1rem;
  }

  .priorityBadges {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.3rem;
    margin-top: 0rem;
  }

  .resolvedText,
  .activeText {
    font-size: 0.8rem;
  }

  .chartPadding {
    width: 100%;
    padding: 1rem 0 0 0;
  }
}

.cardRowTimezone {
  margin-top: 0.1rem;
}
