.chartContainer {
  position: relative;
  height: 100%;
  margin-top: -0.5rem;
}

.tickText {
  font-size: 0.65rem;
  fill: var(--ambient-baseMinus75);
}

.linkText {
  font-size: 0.65rem;
  fill: var(--accent-base);
}
