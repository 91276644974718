.rightAlign,
.titleContainer {
  display: flex;
}

.rightAlign {
  margin-left: 0.125rem;
}

.icon {
  margin-top: -0.4rem;
  margin-right: 0.4rem;
}
