.root {
  height: 3.75rem;
  background-color: var(--ambient-basePlus90);
  padding: 0 3.5rem;
  display: flex;
  align-items: center;
}

.withBackAction {
  padding-left: 0;
}

.content {
  display: flex;
  align-items: center;
}

.content > *:not(:last-child) {
  margin-right: 1rem;
}

.backButton {
  width: 3.5rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--accent-base);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.backButton:hover {
  color: var(--accent-basePlus25);
  background-color: rgba(0, 0, 0, 0.1);
}

.backButton:active {
  color: var(--accent-baseMinus50);
  background-color: rgba(0, 0, 0, 0.15);
}
