.backButton {
  color: var(--accent-base);
  height: 100%;
  display: flex;
  align-items: center;
  background-color: black;
  width: 60px;
  justify-content: center;
  margin-left: -32px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.organizationName {
  font-size: 0.75rem;
  align-items: center;
}

.backButton:hover {
  background-color: transparent;
}

.link,
.link a {
  font-size: 0.75rem;
  font-weight: normal;
}

.organizationMenuContainer {
  padding-right: 2rem;
  font-size: 0.75rem;
}

.organizationMenuTitle {
  padding-left: 0.5rem;
}

.mobileMenuButton:hover {
  background-color: var(--ambient-base);
}

.mobileMenuIcon {
  fill: #ffffff;
}

.glowingButton {
  background-color: var(--ambient-base);
}

.companyLogoWithoutText {
  display: none;
  margin: 0 0.75rem;
  align-self: center;
}

.mobileStaticMenuItem.mobileStaticMenuItem {
  display: none;
  cursor: default;
  color: #ffffff;
}

.mobileStaticMenuItem.mobileStaticMenuItem:hover {
  background: inherit;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .organizationMenuContainer {
    display: none;
  }

  .mobileOrganizationMenuContainer {
    display: block;
  }

  .mobileMenuButton {
    display: flex;
  }

  .companyLogoWithoutText {
    display: flex;
  }

  .userMenuContainer {
    display: none;
  }

  .mobileUserMenuContainer {
    display: block;
  }

  .mobileStaticMenuItem.mobileStaticMenuItem {
    display: block;
  }
}

.downloadGif {
  position: relative;
  width: 35px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  cursor: pointer;
}

.arrowIcon {
  position: relative;
  width: 25px;
  height: 25px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(119deg)
    brightness(140%) contrast(101%);
}

.arrowIcon:after {
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #00a0ae;
  position: absolute;
  top: 8px;
  left: -4px;
  display: inline-block;
}

.arrowAnimated {
  animation: slideDown 1.5s infinite;
  transition: all ease-in 5.45s;
}

.Brackets {
  width: 25px;
  height: 25px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(119deg)
    brightness(140%) contrast(101%);
  margin-top: 5px;
  position: relative;
  top: -3px;
  left: -25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Brackets:after {
  content: '';
  height: 13px;
  width: 2px;
  background-color: #00a0ae;
  left: 0;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.Brackets:before {
  content: '';
  height: 13px;
  width: 2px;
  background-color: #00a0ae;
  right: 0;
  position: absolute;
  bottom: 0;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@keyframes slideDown {
  0% {
    top: -35px;
    transition: all ease-in 0.45s;
  }

  10% {
    top: -30px;
    transition: all ease-in 0.45s;
  }

  20% {
    top: -25px;
    transition: all ease-in 0.45s;
  }

  30% {
    top: -20px;
    transition: all ease-in 0.45s;
  }

  40% {
    top: -15px;
    transition: all ease-in 0.45s;
  }

  50% {
    top: -10px;
    transition: all ease-in 0.45s;
  }

  60% {
    top: -5px;
    transition: all ease-in 0.45s;
  }

  70% {
    top: -3px;
    transition: all ease-in 0.45s;
  }

  80% {
    top: -1px;
    transition: all ease-in 0.45s;
  }

  90% {
    top: 0px;
    transition: all ease-in 0.45s;
  }

  100% {
    top: 1px;
    transition: all ease-in 0.45s;
  }
}

.fileCounter {
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.fileCounter span {
  background-color: #57c466;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .popupContainer {
    right: 50%;
    min-width: 90%;
  }

  .newFeaturePopup {
    min-height: 3rem;
  }
}
